@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.cdnfonts.com/css/cabinet-grotesk");

body {
  margin: 0;
  /* font-family: "Inter", sans-serif; */
  font-family: "Cabinet Grotesk", sans-serif;
}
.homePageHero {
  background-image: linear-gradient(
      to bottom,
      rgba(5, 5, 5, 1) 0%,
      rgba(14, 14, 14, 0.658) 50%,
      rgba(58, 58, 58, 0.465) 100%
    ),
    url(Assets/images/homepage-fero.png);
}
.termsPageHero {
  background-image: linear-gradient(
      to bottom,
      rgba(5, 5, 5, 1) 0%,
      rgba(14, 14, 14, 0.658) 50%,
      rgba(58, 58, 58, 0.465) 100%
    ),
    url(Assets/images/terms-hero.png);
}
.faqsPageHero {
  background-image: linear-gradient(
      to bottom,
      rgba(5, 5, 5, 1) 0%,
      rgba(14, 14, 14, 0.658) 50%,
      rgba(58, 58, 58, 0.465) 100%
    ),
    url(Assets/images/faq-hero.png);
}
.overflowHide {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.overflowHide::-webkit-scrollbar {
  display: none;
}

@layer components {
  .max-width1 {
    @apply max-w-7xl mx-auto p-4;
  }
  .max-width2 {
    @apply max-w-[2000px] mx-auto;
  }
  .text-pry {
    @apply text-[#049805];
  }
  .text-sec {
    @apply text-[#008E56];
  }
  .bg-pry {
    @apply bg-[#049805];
  }
  .bg-sec {
    @apply bg-[#008E56];
  }
}
